<template>
  <div>
    <div class="card">
      <div class="card-body">
        <button type="button" style="float: right"  data-bs-toggle="modal" data-bs-target="#createPermissionGroupModal" class="btn btn-primary px-5">New Permission Group</button>
        <pagination @search='loadPermissionGroups' :data="pagination"></pagination>

        <table id="example" class="table table-hover" style="width:100%">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="group in permissionGroups" :key="group.id" style="cursor: pointer;" @click="$router.push({name: 'admin.management.permissiongroups.view', params: {id: group.id}})">
              <td>{{group.id}}</td>
              <td>{{group.name}}</td>
              <td>{{group.description}}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Description</th>
            </tr>
          </tfoot>
        </table>

        <pagination @search='loadPermissionGroups' :data="pagination"></pagination>
      </div>
    </div>

    <!-- MODALS -->

    <div class="modal fade" id="createPermissionGroupModal" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Create Permission Group</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12 mb-2">
                <label for="permissionGroupName" class="form-label">Permission Group Name</label>
                <input type="text" class="form-control" id="permissionGroupName" v-model="permissionGroup.name">
              </div>
              <div class="col-md-12">
                <label for="permissionGroupDescription" class="form-label">Permission Group Description</label>
                <input type="text" class="form-control" id="permissionGroupDescription" v-model="permissionGroup.description">
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id='closeCreatePermissionGroupModal'>Close</button>
            <submit-button type="button" :background="'btn btn-primary'" :clicked="creating" @click="createPermissionGroup" text="Save changes"></submit-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* global $ */
import axios from 'axios';
import SubmitButton from '../../../../components/SubmitButton.vue';
export default {
  components: { SubmitButton },
  name: 'admin.management.permissiongroups',
  data(){
    return {
      permissionGroups: [],
      pagination: {
        page: 0,
        showing: 0,
        total: 0,
        lastPage: 0
      },
      creating: false,
      permissionGroup: {
        name: '',
        description: ''
      }
    }
  },
  mounted(){
    this.loadPermissionGroups();
  },
  methods: {
    loadPermissionGroups(page = 1){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/permissiongroups?page=${page}`)
      .then(response => {
        this.permissionGroups = response.data.permissionGroups;
        this.pagination = response.data.pagination
      })
      .catch(error => {
        this.$error("Failed to load permission groups.", error);
      });
    },
    createPermissionGroup(){
      if(this.permissionGroup.name.length < 1){
        this.$error("Please enter a valid name!");
        return;
      }
      if(this.permissionGroup.description.length < 1){
        this.$error("Please enter a valid description!");
        return;
      }
      this.creating = true;
      axios.put(`${process.env.VUE_APP_API_URL}/v1/permissiongroups`, this.permissionGroup)
      .then(() => {
        $('#closeCreatePermissionGroupModal').click();
        this.$success("Created new permission group");
        this.creating = false;
        this.permissionGroup = {
          name: '',
          description: ''
        }
        this.loadPermissionGroups(this.pagination.page);
      })
      .catch(error => {
        this.$error("Failed to create new permission group", error);
        this.creating = false;
      });
    },
  }
}
</script>